@import '../../../../sass/vars';

#page-home .section-cards {
  position: relative;
  width: 100%;
  padding: 300px 0 0;
  overflow: hidden;
  z-index: 1;

  @media (max-width: 1023px) {
    padding: 300px 0;
  }

  .container {
    padding-bottom: 16px;
  }

  .flex-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 80px;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
    }

    .left {
      &-title {
        color: white;
        font-size: 48px;
        font-weight: bold;
        width: 280px;
        line-height: 1.1;
        letter-spacing: 0.03em;

        @media (max-width: 1023px) {
          font-size: 24px;
          width: 200px;
        }
      }
    }

    .right {
      @media (max-width: 1023px) {
        margin-top: 10px;
      }

      &-description {
        width: 370px;

        @media (max-width: 1023px) {
          width: 100%;
        }

        p {
          color: #8e8e8e;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.4;
          letter-spacing: 0.03em;

          @media (max-width: 1023px) {
            font-size: 14px;
          }
        }
      }
    }
  }

  .cards {
    position: relative;
    display: flex;
    margin-top: 50px;
    right: -1000px;
    gap: 24px;

    @media (max-width: 1023px) {
      width: calc(100vw - 25px);
      overflow: auto;
      right: 0;
      padding-right: 25px;
    }

    .card {
      position: relative;
      width: 205px;
      height: 275px;
      flex-shrink: 0;

      &:not(:last-child) {
        margin-right: 70px;

        @media (max-width: 1023px) {
          margin-right: 16px;
        }
      }
    }
  }

  .mini-cards {
    position: relative;
    display: flex;
    margin-top: 40px;
    left: -1000px;
    gap: 24px;

    @media (max-width: 1023px) {
      margin-top: 16px;
    }

    .card {
      position: relative;
      width: 205px;
      height: 260px;
      flex-shrink: 0;

      &:not(:last-child) {
        margin-right: 70px;

        @media (max-width: 1023px) {
          margin-right: 16px;
        }
      }
    }
  }
}
