@import '../../../../sass/vars';

#page-home .section-galaxy {
  position: relative;
  width: 100%;
  padding-bottom: 350px;

  @media (max-width: 1023px) {
    padding: 0 0 350px;
  }

  .flex-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100vh;

    @media (max-width: 1023px) {
      height: 500px;
    }

    .left {
      @media (max-width: 1023px) {
        display: none;
      }
    }

    .right {
      width: 435px;

      @media (max-width: 1023px) {
        width: 100%;
      }

      &-title {
        color: white;
        font-size: 40px;
        font-weight: bold;
        letter-spacing: 0.03em;

        @media (max-width: 1023px) {
          font-size: 24px;
        }
      }

      &-description {
        margin-top: 20px;

        @media (max-width: 1023px) {
          margin-top: 10px;
        }

        p {
          color: #eaeaea;
          font-size: 20px;
          font-weight: 500;
          line-height: 1.4;
          letter-spacing: 0.03em;

          @media (max-width: 1023px) {
            font-size: 14px;
          }
        }
      }
    }
  }

  .title {
    color: white;
    font-size: 80px;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.03em;
    height: 100vh;

    @media (max-width: 1023px) {
      font-size: 28px;
      height: 500px;
    }
  }
}
