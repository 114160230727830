// ::-webkit-scrollbar {
//   display: none;
// }

html,
body {
  overscroll-behavior: none;
}

body {
  background-color: #1a1a1e;
  font-family: font(main);
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.container {
  position: relative;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;

  @media (max-width: 1010px) {
    padding: 0 25px;
  }
}
