.content {
  width: 100%;
  padding-bottom: 50px;

  section + section {
    margin-top: 56px;
  }

  .physicalBenefitsSection {
    width: 100%;

    .header {
      margin-bottom: 16px;

      h2 {
        font-weight: 700;
        font-size: 24px;
        letter-spacing: 0.04em;
        color: #FFFFFF;

        margin-bottom: 16px;
      }

      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 124%;
        letter-spacing: 0.03em;
        color: #D2D2D2;

        display: flex;
        gap: 4px;

        @media (max-width: 800px) {
          align-items: center;
        }

        button {
          font-size: 16px;
          font-weight: 400;
          text-decoration-line: underline;
          color: #0DCEFF;

          @media (max-width: 800px) {
            white-space: nowrap;
          }
        }
      }
    }

    .benefit {
      background-color: #191A1D;

      border: 1px solid #222326;
      border-radius: 8px;
      padding: 16px 24px;

      .products {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        gap: 20px;

        @media (max-width: 800px) {
          gap: 6px;
        }

        small {
          font-weight: 500;
          font-size: 24px;
          letter-spacing: 0.03em;
          color: #D2D2D2;

          display: flex;
          align-items: center;
        }

        .productCard {
          flex: 1;

          background-color: #191A1D;

          border: 1px solid #313237;
          border-radius: 8px;

          overflow: hidden;

          @media (max-width: 800px) {
            min-height: 80px;
          }

          img {
            width: 100%;
            display: block;
          }

          span {
            display: inline-block;
            padding: 16px;

            font-weight: 500;
            font-size: 14px;
            line-height: 124%;
            letter-spacing: 0.03em;
            color: #D2D2D2;
          }

          @media (max-width: 800px) {
            span {
              display: none;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }

      h3 {
        font-weight: 700;
        font-size: 24px;
        line-height: 116%;
        letter-spacing: 0.04em;
        color: #D2D2D2;

        margin: 16px 0;

        @media (max-width: 480px) {
          font-size: 16px;
        }
      }

      .claimInfos {
        width: 100%;

        display: flex;
        gap: 16px;

        @media (max-width: 480px) {
          flex-direction: column;
        }

        div {
          display: flex;
          align-items: center;
          gap: 8px;
          
          width: 50%;
          
          padding: 8px 16px;
          border-radius: 8px;

          background-color: #191A1D;
          border: 1px solid #222326;

          @media (max-width: 480px) {
            width: 100%;
          }

          span {
            font-weight: 500;
            font-size: 14px;
            line-height: 124%;
            letter-spacing: 0.03em;
            color: #D2D2D2;
          }
        }

        & + button {
          margin-top: 16px;
        }
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;

        padding: 12px 32px;
        border-radius: 100px;

        width: 100%;
        height: 48px;

        background-color: #0064FF;

        font-weight: 500;
        font-size: 16px;
        line-height: 124%;
        letter-spacing: 0.03em;
        color: #FEFEFE;

        transition: all 0.3s;

        &:not(:disabled):hover {
          background-color: #0014DB;
        }

        &:disabled {
          background-color: #3C3C3B;
          color: #8E8E8E;

          pointer-events: none;
        }
      }
    }
  }

  .othersBenefitsSection {
    width: 100%;

    @media (max-width: 480px) {
      margin-top: 32px;
    }

    .header {
      margin-bottom: 16px;

      h2 {
        font-weight: 700;
        font-size: 24px;
        letter-spacing: 0.04em;
        color: #FFFFFF;

        margin-bottom: 16px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .benefit {
        width: 100%;
        max-height: 230px;

        background-color: #191A1D;
  
        border: 1px solid #222326;
        border-radius: 8px;

        display: flex;
        align-items: stretch;

        overflow: hidden;

        @media (max-width: 480px) {
          max-height: none;

          flex-direction: column;
        }

        .benefitImage {
          width: 100%;
          max-width: 50%;

          overflow: hidden;

          @media (max-width: 480px) {
            max-width: 100%;
          }
  

          img {
            display: block;

            width: 100%;
            height: 100%;
            max-width: 100%;
            object-fit: cover;

            transition: all 0.5s;

            &:hover {
              transform: scale(1.05);
            }
          }
        }

        .benefitInfo {
          width: 100%;
          max-width: 50%;

          padding: 32px;

          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 8px;

          @media (max-width: 800px) {
            padding: 16px;
          }

          @media (max-width: 480px) {
            max-width: 100%;
          }

          h3 {
            font-weight: 500;
            font-size: 18px;
            line-height: 124%;
            letter-spacing: 0.03em;
            color: #D2D2D2;

            @media (max-width: 480px) {
              font-size: 16px;
            }
          }

          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 124%;
            letter-spacing: 0.03em;
            color: #8E8E8E;

            @media (max-width: 480px) {
              font-size: 12px;
            }
          }
    
          a, button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
    
            padding: 12px 32px;
            border-radius: 100px;
    
            width: 100%;
            height: 48px;
    
            background-color: #0064FF;
    
            font-weight: 500;
            font-size: 16px;
            line-height: 124%;
            letter-spacing: 0.03em;
            color: #FEFEFE;
    
            transition: all 0.3s;
    
            &:not(:disabled):hover {
              background-color: #0014DB;
            }
    
            &:disabled {
              background-color: #3C3C3B;
              color: #8E8E8E;
    
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}