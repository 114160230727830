.breadcrumbContainer {
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: 740px) {
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }

 a {
  font-size: 14px;
  letter-spacing: 0.016em;
  color: #8E8E8E;

  white-space: nowrap;
 }

 span {
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #D2D2D2;

  white-space: nowrap;
 }
}