.editForm {
  width: 100%;
  max-width: 564px;
  
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 36px;

  .form {
    width: 100%;
  }

  .boxInput {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 8px;

    margin-bottom: 16px;

    label {
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.03em;
      color: #D2D2D2;
    }

    input {
      background-color: #191A1D;
      border: 1px solid #222326;
      border-radius: 8px;

      height: 50px;
      padding: 16px;

      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.03em;

      color: #FEFEFE;
      
      &::placeholder {
        color: #8E8E8E;
      }

      &:not(:placeholder-shown) {
        border: 1px solid #8E8E8E;
      }
    }
  }

  .boxCopy {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    > span {
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.03em;
      color: #D2D2D2;
    }

    button {
      width: 100%;
      height: 50px;

      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      
      padding: 8px 16px;

      background-color: #26272A;
      border: 1px solid #222326;
      border-radius: 8px;

      span {
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.03em;
        color: #8E8E8E;

        @media (max-width: 1024px) {
          display: block;
          max-width: 264px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .copyIcon {
    position: relative;

    .tooltip {
      position: absolute;
      top: -54px;
      left: -100%;

      span {
        display: block;
        padding: 12px;

        background-color: #313237;
        border-radius: 8px;

        font-size: 12px;
        line-height: 16px;

        color: #FFFFFF;

        position: relative;

        &::after {
          content: "";

          width: 16px;
          height: 16px;
          border-radius: 4px;

          transform: rotate(45deg) translateX(-50%);
          
          background-color: #313237;

          position: absolute;
          bottom: -12px;
          left: 50%;
        }
      }
    }
  }

  .submitButton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    
    padding: 12px 32px;

    width: 171px;
    height: 40px;

    background-color: #0064FF;
    border-radius: 100px;

    transition: all 0.3s;

    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.03em;
    color: #FEFEFE;

    opacity: 1;

    &:not(:disabled):hover {
      background-color: #0014DB;
    }

    &:disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }
}