.holderArea {
  height: 100%;
  min-height: 100vh;

  background-color: #121316;

  main {
    width: 100%;
    max-width: 1200px;
    height: 100%;
    
    margin: 32px auto 0;
    padding: 0 24px;

    display: flex;
    align-items: flex-start;
    gap: 24px;

    @media (max-width: 1024px) {
      max-width: 100%;
      height: auto;
      
      padding: 0 16px;
      margin: 0;
    }
  }
}