.content {
  width: 100%;

  h2 {
    display: none;

    @media (max-width: 1024px) {
      display: block;
      margin-bottom: 24px;

      font-weight: 700;
      font-size: 24px;
      line-height: 116%;
      letter-spacing: 0.04em;
      color: #FEFEFE;
    }
  }

  .claims {
    width: 100%;
  
    display: flex;
    flex-wrap: wrap;
    gap: 16px 25px;
  
    .claimCard {
      width: 100%;
      max-width: calc(50% - 12.5px);
  
      background-color: #191A1D;
      
      border: 1px solid #222326;
      border-radius: 8px;
      padding: 16px;

      @media (max-width: 640px) {
        max-width: 100%;
      }
  
      h3 {
        font-weight: 500;
        font-size: 16px;
        line-height: 124%;
        letter-spacing: 0.03em;
        color: #FEFEFE;
      }
  
      .claimInfo {
        display: flex;
        justify-content: space-between;
  
        margin: 16px 0;
  
        > div {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
  
        strong {
          font-weight: 500;
          font-size: 14px;
          line-height: 124%;
          letter-spacing: 0.03em;
          color: #D2D2D2;
        }
  
        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 124%;
          letter-spacing: 0.03em;
          color: #8E8E8E;
        }
      }
  
      .claimActions {
        display: flex;
        justify-content: space-between;
        align-items: center;
  
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 124%;
          letter-spacing: 0.03em;
          color: #D2D2D2;
        }
  
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          
          padding: 12px 32px;
          border-radius: 100px;
          
          width: 100%;
          max-width: 175px;
  
          background-color: #0064FF;
  
          font-weight: 500;
          font-size: 16px;
          line-height: 124%;
          letter-spacing: 0.03em;
          color: #FEFEFE;
  
          transition: all 0.3s;
  
          &:hover {
            background-color: #0014DB;
          }
        }
      }
    }
  }
}