.menu {
  width: 100%;
  max-width: 270px;
  height: auto;
  max-height: 562px;

  background-color: #191A1D;
  
  border-radius: 8px;
  padding: 16px;

  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    height: 100%;
    
    position: fixed;
    top: 96px;
    right: 0;
    bottom: 0;
    left: 0;

    z-index: 1;

    width: 0;
    overflow: hidden;
    transform: translateX(10px);
    padding: 0;

    transition: all 0.3s;
  }
}

.menuExpanded {
  @media (max-width: 1024px) {
    max-width: 100%;
    height: calc(100vh - 96px);
    max-height: none;

    border-radius: 0;

    width: 100%;
    overflow: auto;
    transform: translateX(0);
    padding: 16px;
  }
}

.profile {
  display: flex;
  align-items: center;
  gap: 16px;

  span {
    font-weight: 700;
    font-size: 16px;
    color: #D2D2D2;
    letter-spacing: 0.03em;

    display: block;
    max-width: 125px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 8px;

    a {
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.03em;
      color: #D2D2D2;

      text-decoration: underline !important;
    }
  }
  
  .profileImg {
    max-width: 70px;
    height: 70px;
    border-radius: 50%;

    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      width: 100%;
      max-width: 100%;
    }
  }
}

.navbar {
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 16px 0;

  a, span {
    width: 100%;

    padding: 8px 16px;
    border-radius: 100px;

    display: flex;
    align-items: center;
    gap: 8px;

    font-weight: 500;
    font-size: 16px;
    color: #8E8E8E;
    letter-spacing: 0.03em;
  }

  a {
    max-width: 222px;
    transition: all 0.2s;

    @media (max-width: 1024px) {
      max-width: 100%;
    }

    &[aria-current="page"], &:hover {
      background-color: #212225;
      color: #D2D2D2;

      svg {
        path {
          fill: #D2D2D2;
        }
      }
    }
  }

  span {
    padding-right: 0;
  }
}

.logoutButton {
  padding: 16px 8px;

  font-weight: 500;
  font-size: 16px;
  color: #8E8E8E;
  text-align: left;
}