.trackingText {
  font-weight: 400;
  font-size: 14px;
  line-height: 124%;
  letter-spacing: 0.03em;
  color: #8E8E8E;
}

.tracking {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  .carrying {
    font-weight: 400;
    font-size: 14px;
    line-height: 124%;
    letter-spacing: 0.03em;
    color: #8E8E8E;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    
    padding: 12px 32px;
    border-radius: 100px;
    
    width: 100%;
    max-width: 175px;

    background-color: #0064FF;

    font-weight: 500;
    font-size: 16px;
    line-height: 124%;
    letter-spacing: 0.03em;
    color: #FEFEFE;

    transition: all 0.3s;

    &:hover {
      background-color: #0014DB;
    }
  }
}