@import '../../../../sass/vars';

#page-home .section-contact {
  position: relative;
  width: 100%;
  padding: 55px 0 75px;

  .flex-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: flex-start;
    }

    &:nth-child(2) {
      border-top: 1px solid #808080;
      padding-top: 32px;
      margin-top: 32px;

      @media (max-width: 1023px) {
        align-items: center;
      }
    }

    .left {
      &-social {
        margin-top: 30px;

        ul {
          display: flex;
          align-items: center;

          li {
            pointer-events: initial;

            &:not(:last-child) {
              margin-right: 16px;
            }

            a {
              position: relative;
              display: inline-block;

              &::after {
                content: '';
                background-color: white;
                position: absolute;
                bottom: 0;
                left: 50%;
                width: 14px;
                height: 8px;
                border-radius: 50%;
                opacity: 0;
                transform: translate(-50%, 5px);
                transition-property: opacity, transform;
                transition-duration: 0.3s;
                transition-timing-function: ease(inout);
                z-index: 1;
              }

              svg {
                position: relative;
                transform: translateY(0);
                transition-property: transform;
                transition-duration: 0.3s;
                transition-timing-function: ease(inout);
              }

              &:hover {
                &::after {
                  opacity: 1;
                  transform: translate(-50%, 0);
                }

                svg {
                  transform: translateY(-10px);
                }
              }
            }
          }
        }
      }

      &-logos {
        display: flex;
        align-items: center;

        a {
          display: inline-block;
          position: relative;

          &:nth-child(3) {
            bottom: -2px;
          }

          img {
            display: block;
          }
        }

        span {
          color: white;
          font-size: 16px;
          font-weight: bold;
          display: inline-block;
          margin: 0 12px;
        }
      }
    }

    .center {
      @media (max-width: 1023px) {
        margin-top: 16px;
      }
    }

    .right {
      &-copyright {
        color: white;
        font-size: 16px;
        font-weight: bold;

        @media (max-width: 1023px) {
          margin-top: 16px;
        }
      }

      &-navigation {
        @media (max-width: 1023px) {
          margin-top: 16px;
        }

        ul {
          li {
            &:not(:last-child) {
              margin-bottom: 16px;
            }

            button,
            a {
              color: white;
              font-size: 16px;
              font-weight: bold;
              display: inline-block;
            }
          }
        }
      }
    }
  }
}
