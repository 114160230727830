.shipping {
  display: flex;
  flex-direction: column;
  gap: 8px;

  strong {
    font-weight: 400;
    font-size: 16px;
    line-height: 124%;
    letter-spacing: 0.03em;
    color: #D2D2D2;
  }

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 124%;
    letter-spacing: 0.03em;
    color: #8E8E8E;
  }
}