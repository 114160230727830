.flakkiesCard {
  max-width: 270px;

  @media (max-width: 640px) {
    width: 100%;
    max-width: 100%;
  }
}

.image {
  width: 100%;
  border-radius: 16px;
  
  overflow: hidden;

  img {
    max-width: 100%;
  }

  @media (max-width: 640px) {
    img {
      width: 100%;
    }
  }
}

.identification {
  width: 100%;
  margin-top: 16px;
  
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & + div {
      margin-top: 4px;
    }

    h2 {
      font-weight: 500;
      font-size: 18px;
      color: #D2D2D2;
      letter-spacing: 0.03em;
    }

    a {
      font-weight: 500;
      font-size: 14px;
      color: #0DCEFF;
      text-decoration: underline !important;
      letter-spacing: 0.03em;
    }

    span {
      font-weight: 400;
      font-size: 14px;
      color: #D2D2D2;
      letter-spacing: 0.03em;
    }
  } 
}