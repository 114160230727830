.claim {
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 48px;
  }

  .claimInfo {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .claimInfoItem {
      h4 {
        font-weight: 500;
        font-size: 14px;
        line-height: 124%;
        letter-spacing: 0.03em;
        color: #D2D2D2;

        margin-bottom: 4px;
      }

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 124%;
        letter-spacing: 0.03em;
        color: #8E8E8E
      }
    }
  }

  .claimResume {
    display: flex;
    flex-direction: column;
    gap: 16px;

    > span {
      font-weight: 500;
      font-size: 14px;
      line-height: 124%;
      letter-spacing: 0.03em;
      color: #8E8E8E;
    }

    .resume {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .resumeCard {
        width: 100%;
        
        display: flex;
        align-items: center;
        gap: 8px;
      
        .resumeCardImg {
          border: 1px solid #313237;
          border-radius: 50%;
      
          overflow: hidden;
      
          width: 50px;
          height: 50px;
      
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      
        .resumeCardInfo {
          span {
            font-size: 16px;
            line-height: 124%;
            letter-spacing: 0.03em;
            color: #FFFFFF;
          }
      
          small {
            display: block;
            margin-top: 4px;
      
            font-size: 12px;
            line-height: 124%;
            letter-spacing: 0.03em;
            color: #8E8E8E;
          }
        }
      }
    }
  }
}
