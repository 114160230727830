@import '../../../sass/vars';

#main-button {
  background-image: linear-gradient(
      82.02deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.2)
    ),
    linear-gradient(90deg, #0064ff, #003fa0, #003fa0, #0064ff);
  box-shadow: 0px 7px 0px #0646a7;
  padding: 16px 32px;
  border-radius: 100px;
  cursor: pointer;
  pointer-events: auto;
  transition-property: box-shadow;
  transition-duration: 0.5s;
  background-size: 300%;
  background-position: left;

  transition: 300ms background-position ease-in-out;

  @media (max-width: 1023px) {
    max-width: 323px;
  }

  &:hover {
    background-position: right;
  }

  &:active {
    background: linear-gradient(
        82.02deg,
        rgba(26, 0, 58, 0) 46.71%,
        rgba(26, 0, 58, 0.2) 95.49%
      ),
      linear-gradient(90deg, #003fa0 0%, #0064ff 100%);
    box-shadow: 0px 0px 0px #0646a7;
    transition-property: box-shadow;
    transition-duration: 0.5s;
  }

  span {
    color: #fefefe;
    font-size: 16px;
    font-weight: 700;

    display: inline-block;
  }
}
