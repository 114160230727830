.toast {
  position: absolute;
  bottom: 26px;
  right: 64px;

  opacity: 0;
  pointer-events: none;

  transform: translateY(20px);

  transition: all 1s;

  background: #3C3C3B;
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.25);
  border-radius: 16px;

  padding: 24px 32px;

  @media (max-width: 1024px) {
    bottom: 40px;
    right: 8px;

    padding: 16px 8px;
  }

  @media (max-width: 480px) {
    position: fixed;
    bottom: 40px;
    right: 16px;
    left: 16px;
  }

  .content {
    display: flex;
    align-items: center;
    gap: 12px;

    width: 100%;
    max-width: 412px;

    @media (max-width: 1024px) {
      max-width: 328px;
    }

    @media (max-width: 480px) {
      justify-content: center;
    }
  }

  span {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.016em;
    line-height: 140%;
    color: #FEFEFE;
  }
}

.toastVisible {
  opacity: 1;
  pointer-events: all;

  transform: translateY(0);
}