.popupBackdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;

  width: 100vw;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(26, 26, 30, 0.9);

  padding: 0 16px;

  .popupPanel {
    width: 100%;
    max-width: 956px;

    position: relative;

    @media (max-width: 1120px) {
      position: static;
    }

    .popupCloseButton {
      position: absolute;
      top: 0;
      right: -64px;

      @media (max-width: 1120px) {
        top: 10px;
        right: 16px;
      }
    }

    .popupContent {
      .popupImage {
        background-color: #2B2B2B;

        img {
          width: 100%;
          max-width: 100%;

          display: block;
        }
      }

      a {
        font-weight: 500;
        font-size: 14px;
        line-height: 124%;
        letter-spacing: 0.03em;
        text-decoration-line: underline !important;
        color: #0DCEFF;

        display: none;
        margin-top: 8px;

        @media (max-width: 800px) {
          display: block;
        }
      }
    }
  }
}