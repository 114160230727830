.profileArea {
  min-height: 100vh;

  background-color: #121316;

  & > aside {
    @media(min-width: 1025px) {
      display: none;
    }
  }

  main {
    width: 100%;
    max-width: 1200px;
    height: calc(100% - 144px);
    
    margin: 32px auto 0;
    padding: 0 24px;

    @media (max-width: 1024px) {
      max-width: 100%;
      height: auto;
      
      padding: 0 16px;
      margin: 0;
    }

    > h1 {
      display: none;

      @media (max-width: 1024px) {
        display: block;

        font-weight: 700;
        font-size: 24px;
        letter-spacing: 0.04em;
        color: #FFFFFF;

        margin-top: 10px;
      }
    }
  }

  .content {
    width: 100%;
    padding: 40px 0;

    @media (max-width: 1024px) {
      padding: 32px 0;
    }
  }
}