#container {
  background-color: rgba(26, 26, 30, 0.88);
  z-index: 2;
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 400ms ease-in;

  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    z-index: 3;
    background-color: #fefefe;
    opacity: 1;
    width: 30vw;
    height: 50vh;
    max-height: 400px;
    padding: 32px;
    box-shadow: 0px 0px 16px rgba(254, 254, 254, 0.08);
    border-radius: 16px;
    color: #1a1a1e;

    @media (max-width: 1023px) {
      width: 100%;
      height: 55%;
      max-height: 100%;
      position: fixed;
      bottom: 0;
      border-radius: 24px 24px 0px 0px;
    }

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
      font-size: 20px;
      line-height: 140%;

      .close-button {
        pointer-events: auto;
        cursor: pointer;
      }
    }
  }
}
