@import '../../../sass/vars';

.header.expanded {
  background-color: #1a1a1e;
  height: 100vh;
}

#main-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;

  .flex-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0;
    pointer-events: none;

    .logo {
      position: relative;
      pointer-events: initial;

      svg {
        display: block;
        transition-property: opacity;
        transition-duration: time(fast);
        transition-timing-function: ease(inout);

        &:nth-child(1) {
          position: relative;
          opacity: 1;
        }

        &:nth-child(2) {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
        }
      }
    }

    .hamburger {
      border: 0;
      height: 40px;
      width: 40px;
      cursor: pointer;
      display: none;
      pointer-events: auto;
      margin-left: auto;

      @media (max-width: 1023px) {
        display: block;
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 32px;
      position: relative;

      @media (max-width: 1023px) {
        width: 0;
      }
    }

    .navigation.expanded ul {
      @media (max-width: 1023px) {
        display: block;
      }
    }

    .navigation {
      margin-right: 32px;

      ul {
        display: flex;
        align-items: center;

        @media (max-width: 1023px) {
          display: none;
          position: fixed;
          top: 85px;
          right: 0;
          bottom: 0;
          left: 0;
          flex-direction: column;
          align-items: center;
          width: 100%;
          background-color: #1a1a1e;
        }

        li {
          pointer-events: initial;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          &:not(:last-child) {
            @media (min-width: 1023px) {
              margin-right: 24px;
            }
          }

          @media (max-width: 1023px) {
            width: 100vh;
          }

          button,
          a,
          p {
            color: #fff;
            font-size: 16px;
            font-weight: bold;
            display: inline-block;

            @media (max-width: 1023px) {
              margin-top: 16px;
              margin-left: 24px;
            }
          }

          .line {
            @media (max-width: 1023px) {
              display: none;
            }

            align-items: center;
            height: 6px;
            width: 12px;
            background-color: white;
            display: none;
            border-radius: 30%;
            margin-top: 6px;
          }

          &:hover {
            @media (min-width: 1023px) {
              margin-bottom: 16px;

              .line {
                display: block;
              }
            }
          }

          &.button-holder {
            width: 100%;

            position: absolute;
            bottom: 40px;
            right: 0;
            left: 0;

            button {
              width: 100%;
            }
          }
        }
      }
    }

    .wallet.expanded {
      @media (max-width: 1023px) {
        position: fixed;
        display: inline-block;
        text-align: center;
        width: 90%;
        bottom: 30px;
        margin: 0;
      }
    }

    .wallet {
      margin-left: 32px;
      @media (max-width: 1023px) {
        display: none;
      }

      .wallet-address {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 227px;
        height: 55px;
        background-color: transparent;
        border: 1px solid #646464;
        border-radius: 71px;

        span {
          color: white;
          font-size: 16px;
          font-weight: bold;
          display: inline-block;
        }

        .wallet-connected {
          width: 8px;
          height: 8px;
          background-color: #21db53;
          border-radius: 50%;
        }
      }
    }

    .wallet-button-disconnect {
      cursor: pointer;
      pointer-events: auto;
    }

    .language {
      position: relative;
      pointer-events: initial;

      @media (max-width: 1023px) {
        display: none;
      }

      &::before {
        content: '';
        background-color: transparent;
        border: 1px solid white;
        position: absolute;
        top: -8px;
        right: -8px;
        left: -8px;
        height: 96px;
        pointer-events: none;
        transform-origin: 50% 0%;
        transform: scaleY(0);
        transition-property: transform;
        transition-duration: time(default);
        transition-timing-function: ease(inout);
        border-radius: 8px;
      }

      > button {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        width: 80px;
        height: 40px;
        padding: 0 8px;
        border-radius: 8px;
        transition-property: background-color;
        transition-duration: time(default);
        transition-timing-function: ease(inout);
        z-index: 2;

        span {
          color: #fefefe;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.03em;
          display: inline-block;
          margin-left: 8px;
        }

        svg {
          position: absolute;
          top: 50%;
          right: 8px;
          transform: translateY(-50%);
          transition-property: transform;
          transition-duration: time(default);
          transition-timing-function: ease(inout);
        }
      }

      .option {
        position: absolute;
        top: calc(100% + 16px);
        pointer-events: none;
        opacity: 0;
        transform: translateY(5px);
        transition-property: opacity, transform;
        transition-duration: time(default);
        transition-timing-function: ease(inout);

        button {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          position: relative;
          padding: 0 8px;
          border-radius: 8px;
          transition-property: background-color;
          transition-duration: time(default);
          transition-timing-function: ease(inout);
          z-index: 2;

          span {
            color: #fefefe;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0.03em;
            display: inline-block;
            margin-left: 8px;
            transition-property: color;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
          }
        }
      }

      &:hover {
        &::before {
          transform: scaleY(1);
          pointer-events: initial;
        }

        > button {
          svg {
            transform: translateY(-50%) rotate(180deg);
          }
        }

        .option {
          pointer-events: initial;
          opacity: 1;
          transform: translateY(0);
          transition-delay: 0.2s;
        }
      }
    }

    .button-holder {
      min-width: 250px;
    }
  }

  .modal {
    width: 45vw;
    height: auto;

    padding: 40px 64px;

    @media (max-width: 1024px) {
      width: 100%;

      padding: 40px 16px;
    }

    .header {
      display: none;
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;
  
      .error-info {
        h2 {
          font-weight: 700;
          font-size: 20px;
          letter-spacing: 0.03em;
          color: #1A1A1E;
          text-align: center;
  
          margin-bottom: 16px;
        }
  
        p {
          font-size: 14px;
          letter-spacing: 0.03em;
          color: #1A1A1E;
          line-height: 124%;

          text-align: center;
        }
      }

      button {
        min-width: 210px;

        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px 32px;

        background-color: #0064FF;
        border-radius: 100px;

        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.03em;
        color: #FEFEFE;
      }
    }
  }
}