@import '../../../../sass/vars';

#page-home .section-hero {
  position: relative;
  width: 100%;
  z-index: 2;

  .clouds {
    position: absolute;
    right: 0;
    bottom: -140px;
    left: 0;
    z-index: 1;
    width: 100vw;

    .cloud {
      position: absolute;
      bottom: 190px;
      @media (max-width: 1023px) {
        position: absolute;
        bottom: 190px;
      }
    }

    .clouds-upside-down {
      position: absolute;
      top: -198px;
      transform: rotate(180deg);
    }
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .flex-wrapper {
    position: relative;
    width: 100%;
    padding: 180px 0 380px;
    min-height: 100vh;

    @media (max-width: 1023px) {
      padding: 110px 0 0;
      height: 800px;
      min-height: auto;
      align-items: flex-start;
    }

    .green-blur {
      position: absolute;
      left: -400px;
      bottom: 100px;
    }

    .blue-blur {
      position: absolute;
      right: -500px;
      top: -200px;
    }

    .wrapper {
      position: relative;
      width: 710px;
      z-index: 2;

      @media (max-width: 1023px) {
        max-width: 100%;
      }

      &-title {
        color: #fefefe;
        font-size: 48px;
        font-weight: bold;
        line-height: 1.3;
        max-width: 472px;

        @media (max-width: 1023px) {
          font-size: 32px;
          padding-right: 40px;
        }
      }

      &-description {
        width: 480px;
        margin-top: 16px;

        @media (max-width: 1023px) {
          width: 100%;

          br {
            display: none;
          }
        }

        p {
          color: #d2d2d2;
          font-size: 18px;
          font-weight: normal;
          line-height: 1.5;

          @media (max-width: 1023px) {
            font-size: 12px;
          }
        }
      }

      &-cta {
        @media (max-width: 1023px) {
          margin-top: 16px;
        }

        max-width: 240px;
        height: 48px;
        border-radius: 100px;
        font-size: 16px;
        font-weight: bold;
        margin-top: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: linear-gradient(
            82.02deg,
            rgba(255, 255, 255, 0.2),
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.2)
          ),
          linear-gradient(90deg, #0064ff, #003fa0, #003fa0, #0064ff);
        box-shadow: 0px 7px 0px #0646a7;
        cursor: pointer;
        transition-property: box-shadow;
        transition-duration: 0.5s;

        background-size: 300%;
        background-position: left;

        transition: 300ms background-position ease-in-out;

        span {
          color: #fefefe;
        }

        @media (max-width: 1023px) {
          width: 80vw;
          min-height: auto;
          max-width: 182px;
        }

        &:hover {
          background-position: right;
        }

        &:active {
          box-shadow: 0px 0px 0px #0646a7;
          transition-property: box-shadow;
          transition-duration: 0.5s;
        }
      }
    }

    @keyframes hero_galaxy {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(-360deg);
      }
    }

    .galaxy {
      position: absolute;
      top: 135px;
      right: -120px;
      width: 476px;
      height: 476px;
      user-select: none;
      pointer-events: none;

      .card-blur {
        position: absolute;
        top: -135px;
        right: -157px;
      }

      @media (max-width: 1023px) {
        top: unset;
        right: unset;
        bottom: 0;
        left: 55%;
        transform: translateX(-50%) scale(0.6);
      }
    }
  }
}
