@import '../../../sass/vars';

#main-preloader {
  @extend %cover;

  background-color: #036fff;
  transition-property: opacity;
  transition-duration: time(slow);
  transition-timing-function: ease(inout);
  z-index: 100;

  @keyframes logo_vector_animation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .logo-vector {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.7);
  }

  &.remove {
    opacity: 0;
    pointer-events: none;
  }
}
