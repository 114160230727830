.modalBackdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 999;

  width: 100vw;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(26, 26, 30, 0.9);

  @media (max-width: 640px) {
    align-items: flex-end;
  }

  .modalPanel {
    background-color: #191A1D;

    border: 1px solid #3C3C3B;
    border-radius: 8px;
    padding: 24px 16px;

    @media (max-width: 640px) {
      border: 0;
      border-top: 1px solid #3C3C3B;
    }
    
    header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        font-weight: 500;
        font-size: 18px;
        letter-spacing: 0.03em;
        color: #FEFEFE;
      }

      button {
        width: 48px;
        height: 48px;

        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 28px;
          height: 28px;
        }
      }
    }

    .modalContent {
      margin-top: 8px;
      max-width: 435px;

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 124%;
        letter-spacing: 0.03em;
        color: #D2D2D2;
      }

      .imageUpload {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin: 24px 0;

        > label {
          display: flex;
          align-items: center;
          justify-content: center;

          width: 100px;
          height: 100px;
      
          border-radius: 50%;
          overflow: hidden;
      
          cursor: pointer;
          position: relative;
      
          img {
            width: 100%;
          }
      
          &::after {
            content: "";
      
            width: 100%;
            height: 100%;
      
            transition: all 0.3s ease-in-out;
      
            background-color: rgba(31, 31, 31, 0.5);
            background-image: url("../../../../assets/vectors/icon-camera.svg");
            background-repeat: no-repeat;
            background-position: center;
      
            position: absolute;
            top: 0;
            left: 0;
      
            border-radius: 50%;
          }
        }
      }

      .imageList {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        margin-bottom: 24px;
      }

      > button {
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        padding: 12px 32px;

        background-color: #0064FF;
        border-radius: 100px;

        font-weight: 500;
        font-size: 16px;
        letter-spacing: 0.03em;
        color: #FEFEFE;

        &:not(:disabled):hover {
          background-color: #0014DB;
        }

        &:disabled {
          background-color: #3C3C3B;
          color: #8E8E8E;

          pointer-events: none;
        }
      }
    }
  }
}