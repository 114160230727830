@import '../../../sass/vars';

#main-flakkies {
  will-change: transform;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 4;

  .rocket {
    position: absolute;
    bottom: -80px;
    left: -170px;
    opacity: 0;
    transform: translateY(50px);
    transition-property: opacity, transform;
    transition-duration: time(slow);
    transition-timing-function: ease(inout);
    z-index: 2;

    @media (max-width: 1023px) {
      display: none;
    }
  }

  &.active {
    pointer-events: initial;

    .rocket {
      opacity: 1;
      transform: translateY(0);
    }

    .flex-wrapper {
      opacity: 1;
    }
  }

  .flex-wrapper {
    position: relative;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    transform: translateX(100%);
    transition-property: opacity;
    transition-duration: time(slow);
    transition-timing-function: ease(inout);
    z-index: 1;

    .item {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: 100vw;
      height: 100vh;

      @media (max-width: 1023px) {
        flex-direction: column;
        align-items: flex-start;
        padding: 0 25px;
      }

      &-left {
        width: 490px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        padding-top: 50px;
        margin-right: 150px;

        @media (max-width: 1023px) {
          width: 100%;
          margin: 0 0 20px;
        }

        &--rarity {
          color: white;
          font-size: 24px;
          font-weight: bold;
          display: flex;
          align-items: center;
          padding: 0 15px;
          height: 45px;

          @media (max-width: 1023px) {
            font-size: 16px;
            height: 35px;
          }
        }

        &--title {
          color: white;
          font-size: 48px;
          font-weight: bold;
          line-height: 1.2;
          letter-spacing: 0.03em;
          margin-top: 25px;

          @media (max-width: 1023px) {
            font-size: 24px;
            margin-top: 10px;
          }
        }

        &--description {
          margin-top: 15px;

          @media (max-width: 1023px) {
            margin-top: 10px;
          }

          p {
            color: #fefefe;
            font-size: 18px;
            font-weight: normal;
            line-height: 1.6;
            letter-spacing: 0.03em;

            @media (max-width: 1023px) {
              font-size: 14px;
            }
          }
        }
      }

      &-right {
        position: relative;

        &--title {
          color: #fefefe;
          font-size: 120px;
          font-weight: bold;
          letter-spacing: 0.03em;
          white-space: nowrap;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;

          @media (max-width: 1023px) {
            top: 5px;
            font-size: 48px;
            transform: translateX(-50%);
          }
        }

        &--planet {
          position: relative;
          width: 310px;
          height: 400px;
          z-index: 1;

          @media (max-width: 1023px) {
            width: 240px;
            height: 305px;
          }

          &_image {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;

            img {
              display: block;
              width: 100%;
              user-select: none;
              pointer-events: none;
            }
          }
        }

        &--card {
          position: absolute;
          bottom: -60px;
          left: -80px;
          width: 135px;
          height: 172px;
          flex-shrink: 0;
          z-index: 2;

          @media (max-width: 1023px) {
            left: 180px;
            bottom: -20px;
          }

          @keyframes flakkies_card_gradient {
            0% {
              background-position: top left;
            }
            100% {
              background-position: bottom right;
            }
          }

          &_gradient {
            @extend %cover;

            background-size: 200% 200%;
            background-position: top left;
            animation-name: flakkies_card_gradient;
            animation-duration: 2s;
            animation-direction: alternate;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            border-radius: 8px;
            overflow: hidden;
            z-index: 2;
          }

          &_radial {
            background: radial-gradient(
              49.81% 49.81% at 50% 50.19%,
              #dcd6d6 0%,
              #939393 100%
            );
            position: absolute;
            top: 2px;
            right: 2px;
            bottom: 2px;
            left: 2px;
            border-radius: 8px;
            overflow: hidden;
            z-index: 2;
          }

          &_title {
            position: absolute;
            top: 8px;
            right: 8px;
            display: flex;
            align-items: center;
            z-index: 3;

            span {
              color: #fefefe;
              font-size: 14px;
              font-weight: bold;
              display: inline-block;
              margin-left: 4px;
            }
          }

          &_rarity {
            color: #fefefe;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0.03em;
            position: absolute;
            bottom: 8px;
            left: 8px;
            z-index: 3;
          }

          &_image {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 100%;
            height: 100%;
            padding: 0 10px;
            z-index: 3;

            img {
              width: 100%;
              display: block;
              user-select: none;
              pointer-events: none;
            }
          }
        }
      }
    }

    .item:nth-child(1) {
      .item-left {
        &--rarity {
          background-color: #ff4c00;
        }
      }

      .item-right {
        &--planet {
          background-color: #ff4c00;
        }

        &--card {
          &_gradient {
            background-image: linear-gradient(
              to bottom right,
              #ff7900 0%,
              fade-out(#ffe50c, 0.2) 50%,
              #ff7900 100%
            );
          }
        }
      }
    }

    .item:nth-child(2) {
      .item-left {
        &--rarity {
          background-color: #007300;
        }
      }

      .item-right {
        &--planet {
          background-color: #007300;
        }

        &--card {
          &_gradient {
            background-image: linear-gradient(
              to bottom right,
              #21db53 0%,
              #14a7ff 50%,
              #21db53 100%
            );
          }
        }
      }
    }

    .item:nth-child(3) {
      .item-left {
        &--rarity {
          background-color: #646464;
        }
      }

      .item-right {
        &--planet {
          background-color: #646464;
        }

        &--card {
          &_gradient {
            background-image: linear-gradient(
              to bottom right,
              #ff427b 0%,
              #8e8e8e 50%,
              #ff427b 100%
            );
          }
        }
      }
    }

    .item:nth-child(4) {
      .item-left {
        &--rarity {
          background-color: #980086;
        }
      }

      .item-right {
        &--planet {
          background-color: #980086;
        }

        &--card {
          &_gradient {
            background-image: linear-gradient(
              to bottom right,
              #00c9a2 0%,
              #953fdb 50%,
              #00c9a2 100%
            );
          }
        }
      }
    }
  }
}
