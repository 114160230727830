.cardInput {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - 4px);
  
  &:only-child {
    flex-grow: 1;
  }

  input:checked + label {
    background-color: #212225;
    border: 1px solid #313237;

    &::after {
      content: "";

      width: 24px;
      height: 24px;

      background-image: url("../../../../assets/vectors/icon-check-filled-purple.svg");
    }
  }

  .label {
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    padding: 8px;
    border-radius: 4px;
  
    cursor: pointer;
  
    &:hover {
      background-color: #262527;
    }
  
    .cardInfo {
      display: flex;
      align-items: center;
      gap: 8px;
  
      .cardSelectImage {
        width: 55px;
        height: 55px;
  
        overflow: hidden;
  
        border-radius: 4px;
  
        img {
          width: 100%;
          max-width: 100%;
        }
      }
  
      span, small {
        display: block;
  
        font-weight: 500;
        font-size: 14px;
        line-height: 124%;
        letter-spacing: 0.03em;
        color: #FEFEFE;
      }
  
      small {
        color: #D2D2D2;
      }
    }
  }
}

