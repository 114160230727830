@import '../../../sass/vars';

#main-planet {
  will-change: transform;
  position: fixed;
  bottom: 0;
  left: 0;
  transform: scale(0) translate(-50%, 50%);
  z-index: 2;

  &.first {
    transform-origin: 0% 100%;
  }

  &.second {
    transform-origin: 25% 50%;
  }

  * {
    will-change: transform;
  }
}
