.appear-fade,
.appear-fade-horizontal,
.appear-fade-vertical {
  opacity: 0;
  transition-property: opacity, transform;
  transition-duration: 0.5s;
  // transition-timing-function: cubic-bezier(0.555, 0.32, 0.205, 0.63);
  transition-timing-function: ease(inout);

  &.appear {
    opacity: 1;
    transform: translate(0, 0);
    transition-duration: 2.5s;
  }
}

.appear-fade-vertical {
  transform: translate(0, 15px);
}

.appear-fade-horizontal {
  transform: translate(15px, 0);
}
