#main-cursor {
  will-change: transform;
  position: fixed;
  top: -34px;
  left: -34px;
  transform: translate(-34px, -34px);
  pointer-events: none;
  z-index: 80;

  @media (max-width: 1023px) {
    display: none;
  }

  @keyframes cursor_rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  img {
    display: block;
    animation-name: cursor_rotate;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}
