.imagePreview {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  > button {
    width: 100%;
    max-width: 172px;
    height: 172px;

    border-radius: 50%;
    overflow: hidden;

    cursor: pointer;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
    }

    &::after {
      content: "";

      width: 0;
      height: 0;

      transition: all 0.3s ease-in-out;

      background-color: rgba(31, 31, 31, 0.5);
      background-image: url("../../../../assets/vectors/icon-edit.svg");
      background-repeat: no-repeat;
      background-position: center;

      position: absolute;
      top: 50%;
      left: 50%;

      border-radius: 50%;
    }

    @media (min-width: 1025px) {
      &:hover::after {
        width: 100%;
        height: 100%;
        
        top: 0;
        left: 0;
      }
    }

    @media (max-width: 1024px) {
      max-width: 156px;
      height: 156px;

      &::after {
        width: 100%;
        height: 100%;
        
        top: 0;
        left: 0;
      }
    }
  }

  span {
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #D2D2D2;
  }
}