.header {
  width: 100%;

  padding: 24px 0;
  background-color: #121316;
}

.headerContainer {
  width: 100%;
  max-width: 1200px;
  
  margin: 0 auto;
  padding: 0 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1024px) {
    max-width: 100%;
    padding: 0 16px;
  }

  .language {
    position: relative;
    pointer-events: initial;
  
    @media (max-width: 1024px) {
      display: none;
    }
  
    &::before {
      content: '';
      background-color: transparent;
      border: 1px solid white;
      position: absolute;
      top: -8px;
      right: -8px;
      left: -8px;
      height: 96px;
      pointer-events: none;
      transform-origin: 50% 0%;
      transform: scaleY(0);
      transition-property: transform;
      transition-duration: time(default);
      transition-timing-function: ease(inout);
      border-radius: 8px;
    }
  
    > button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      width: 80px;
      height: 40px;
      padding: 0 8px;
      border-radius: 8px;
      transition-property: background-color;
      transition-duration: time(default);
      transition-timing-function: ease(inout);
      z-index: 2;
  
      span {
        color: #fefefe;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.03em;
        display: inline-block;
        margin-left: 8px;
        text-transform: uppercase;
      }
  
      svg {
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        transition-property: transform;
        transition-duration: time(default);
        transition-timing-function: ease(inout);
      }
    }
  
    &:hover {
      &::before {
        transform: scaleY(1);
        pointer-events: initial;
      }
  
      > button {
        svg {
          transform: translateY(-50%) rotate(180deg);
        }
      }
  
      .option {
        pointer-events: initial;
        opacity: 1;
        transform: translateY(0);
        transition-delay: 0.2s;
      }
    }
  
    .option {
      position: absolute;
      top: calc(100% + 16px);
      pointer-events: none;
      opacity: 0;
      transform: translateY(5px);
      transition-property: opacity, transform;
      transition-duration: time(default);
      transition-timing-function: ease(inout);
    
      button {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        padding: 0 8px;
        border-radius: 8px;
        transition-property: background-color;
        transition-duration: time(default);
        transition-timing-function: ease(inout);
        z-index: 2;
    
        span {
          color: #fefefe;
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 0.03em;
          display: inline-block;
          margin-left: 8px;
          transition-property: color;
          transition-duration: time(default);
          transition-timing-function: ease(inout);
        }
      }
    }
  }

  .menuMobile {
    display: none;

    @media (max-width: 1024px) {
      display: block;
    }
  }
}