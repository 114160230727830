@import '../../../sass/vars';

#main-stars {
  @extend %cover;

  position: fixed;
  z-index: 1;

  .main-clouds {
    width: 100vw;
    height: 100vh;
  }
}
