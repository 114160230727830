.claimDetails {
  min-height: 100vh;

  background-color: #121316;

  & > aside {
    @media(min-width: 1025px) {
      display: none;
    }
  }

  main {
    width: 100%;
    max-width: 1200px;
    
    margin: 32px auto 0;
    padding: 0 24px;

    @media (max-width: 1024px) {
      max-width: 100%;
      
      padding: 0 16px;
      margin: 0;
    }
  }

  .content {
    width: 100%;
    max-width: 800px;
    margin: 45px auto 0;
    padding-bottom: 50px;

    @media (max-width: 1024px) {
      margin-top: 25px;
      padding-bottom: 25px;
    }

    h2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 124%;
      letter-spacing: 0.03em;
      color: #FFFFFF;

      margin-bottom: 16px;

      @media (max-width: 1024px) {
        margin-bottom: 25px;
      }
    }

    .details {
      width: 100%;

      display: flex;
      flex-direction: column;
      gap: 24px;

      @media (max-width: 1024px) {
        gap: 16px;
      }

      .detailsBox {
        width: 100%;

        background-color: #191A1D;

        border: 1px solid #222326;
        border-radius: 8px;
        padding: 16px;

        h3 {
          font-weight: 800;
          font-size: 18px;
          line-height: 124%;
          letter-spacing: 0.03em;
          color: #D2D2D2;

          margin-bottom: 16px;
        }
      }

      .help {
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 124%;
          letter-spacing: 0.03em;
          color: #D2D2D2;

          a {
            text-decoration-line: underline !important;
            color: #0DCEFF;
          }
        }
      }
    }
  }
}