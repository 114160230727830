.content {
  width: 100%;

  h1 {
    display: none;

    @media (max-width: 1024px) {
      display: block;
      margin-bottom: 24px;

      font-weight: 700;
      font-size: 24px;
      letter-spacing: 0.04em;
      color: #FFFFFF;
    }
  }

  .cards {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    @media (max-width: 640px) {
      gap: 32px;
    }
  }
}